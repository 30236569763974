import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import vinyle from "./../assets/images/vynyle.png";
import { Col, Container, Row } from "reactstrap";
import WavesurferPlayer from "@wavesurfer/react";
import { FaHeart, FaRegHeart, FaRegPlayCircle } from "react-icons/fa";
import { FaRegCirclePause } from "react-icons/fa6";
import * as MusicService from "../services/music";
import ItemTrack from "./ItemTrack";
import { useProfile } from "../hooks/UserHooks";

export const Footer = ({ selectedTrack = null, isFav, toogleFav }) => {
  const { setVolumeTrack, defaultVolume } = useProfile();

  const HandleLastTrack = () => {
    if (!selectedTrack) {
      MusicService.getList().then((response) => {
        if (response.lentgh > 0) {
          selectedTrack = response[0];
        }
      });
    }
  };

  useEffect(() => {
    HandleLastTrack();
  }, []);

  return (
    <div className="footer px-3">
      {/* <ItemTrack track={selectedTrack}></ItemTrack> */}
      {/* <div className="volume-container">
        <input
          className="volume"
          id="volume"
          onChange={(e) => {
            setVolumeTrack(() => e.target.value);
            // setVolumeTrack(e.target.value);
          }}
          type="range"
          min="0"
          max="1"
          // value={volumeTrack}
          step="0.01"
        />
      </div> */}
    </div>
  );
};
