import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Col, Container, Form, FormFeedback, Input, Label } from "reactstrap";
import { Footer } from "../../components/Footer";
import Header from "../../components/Header";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import * as UserService from "../../services/user";
import VerticalLayout from "../../components/VerticalLayout";

const Register = () => {
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      lastname: "",
      firstname: "",
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: yup.object({
      lastname: yup.string().required("Nom obligatoire"),
      firstname: yup.string().required("Prénom obligatoire"),
      email: yup.string().email("Email invalide").required("Email obligatoire"),
      password: yup.string().required("Mot de passe obligatoire").min(6, "minimum 6 charactères"),
      confirmPassword: yup.string().when("password", (password, field) => (password ? field.required().oneOf([yup.ref("password")]) : field))
    }),
    onSubmit: (values) => {
      UserService.create(values)
        .then((res) => {
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);

          toast.error(err.data.message);
        });
    }
  });

  return (
    <>
      <VerticalLayout />

      <div className="body mb-0">
        <Header />

        <ToastContainer />
        <div className="bg-main-container">
          <div className="main-container scrollable">
            <Container className="width-form">
              <div className="dark-container rounded p-4">
                <h2 className="text-white ">Inscription</h2>
                <Form
                  onSubmit={validation.handleSubmit}
                  className="mt-5">
                  <Col className="m-3">
                    <Input
                      name="lastname"
                      invalid={validation.touched.lastname && validation.errors.lastname}
                      placeholder="Nom"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastname}
                    />
                    {validation.errors.lastname && validation.touched.lastname && <FormFeedback>{validation.errors.lastname}</FormFeedback>}
                  </Col>
                  <Col className="m-3">
                    <Input
                      name="firstname"
                      invalid={validation.touched.firstname && validation.errors.firstname}
                      placeholder="Prénom"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname}
                    />
                    {validation.errors.firstname && validation.touched.firstname && <FormFeedback>{validation.errors.firstname}</FormFeedback>}
                  </Col>
                  <Col className="m-3">
                    <Input
                      name="email"
                      invalid={validation.touched.email && validation.errors.email}
                      placeholder="Email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email}
                    />
                    {validation.errors.email && validation.touched.email && <FormFeedback>{validation.errors.email}</FormFeedback>}
                  </Col>
                  <Col className="m-3">
                    <Input
                      name="password"
                      invalid={validation.touched.password && validation.errors.password}
                      placeholder="Mot de passe"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password}
                      type="password"
                    />
                    {validation.errors.password && validation.touched.password && <FormFeedback>{validation.errors.password}</FormFeedback>}
                  </Col>
                  <Col className="m-3">
                    <Input
                      name="confirmPassword"
                      invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
                      placeholder="Confimer mot de passe"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmPassword}
                      type="password"
                    />
                    {validation.errors.confirmPassword && validation.touched.confirmPassword && <FormFeedback>{validation.errors.confirmPassword}</FormFeedback>}
                  </Col>
                  <div className="d-flex m-2">
                    <Link
                      className="text-decoration-none"
                      to={"/register"}>
                      <p className="text-white">déjà inscrit ? connexion !</p>
                    </Link>
                  </div>
                  <Button
                    color="primary"
                    className="">
                    Connexion
                  </Button>
                </Form>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
