import { instance } from "./instanceApi";

let path_api = "/users";

export const getList = () => {
  return new Promise((resolve, reject) => {
    instance.get(`${path_api}`).then((res) => {
      resolve(res.data);
    });
  });
};

export const getOne = (id) => {
  return new Promise((resolve, reject) => {
    instance.get(`${path_api}/` + id).then((res) => {
      resolve(res.data[0]);
    });
  });
};

export const create = (data) => {
  return new Promise((resolve, reject) => {
    instance.post(`${path_api}`, data).then((res) => {
      resolve(res.data);
    });
  });
};

export const update = (data) => {
  return new Promise((resolve, reject) => {
    instance.patch(`${path_api}/${data.id}`, data).then((res) => {
      resolve(res.data);
    });
  });
};
