import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col } from "reactstrap";
import { FaArrowDown, FaDownload, FaRegHeart, FaRegPlayCircle } from "react-icons/fa";
import WavesurferPlayer, { useWavesurfer } from "@wavesurfer/react";
import { FaHeart, FaRegCirclePause } from "react-icons/fa6";
import { useProfile } from "../hooks/UserHooks";
import vinyle from "./../assets/images/vynyle.png";
import { Link } from "react-router-dom";
import { MdFileDownload } from "react-icons/md";
import WaveSurfer from "wavesurfer.js";
import { createPortal } from "react-dom";
const ItemTrack = ({ onClick, isPlay, track, isFav = false, toogleFav = () => {}, volume = 1 }) => {
  const [mediaElementState, setMediaElementState] = useState(null);

  const containerRef = useRef(null);

  const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    url: track.path,
    waveColor: "white",
    height: 35
  });

  const onPlayPause = () => {
    onClick();
    setTimeout(() => {
      wavesurfer && wavesurfer.playPause();
    }, 100);
  };

  useEffect(() => {
    if ((wavesurfer, isReady)) {
      setMediaElementState(wavesurfer.getMediaElement());
    }
  }, [wavesurfer, isReady]);

  const ItemTest = useCallback(() => {
    return (
      <div
        id="test"
        style={{ position: "fixed", bottom: 0, width: "100%", zIndex: 8000 }}>
        <div className="d-flex align-items-center w-100">
          <div className="px-3 w-100 d-flex align-items-center">
            <img
              height={60}
              src={vinyle}></img>
            <p className="m-0 w-25">{track?.title}</p>
            <div className="d-flex">
              <span
                onClick={() => onPlayPause()}
                role="button"
                className="hover-btn mx-2">
                {isPlaying ? (
                  <FaRegCirclePause
                    fill={"#c91c1c"}
                    size={"25px"}
                  />
                ) : (
                  <FaRegPlayCircle
                    fill={"#c91c1c"}
                    size={"25px"}
                  />
                )}
              </span>
            </div>
            <div>{wavesurfer && JSON.stringify(Math.round(wavesurfer.getDuration()) / 100).replace(".", ":")}</div>
            <div className="waveform mx-2 w-75">
              <WavesurferPlayer
                media={mediaElementState}
                height={35}
                waveColor={"white"}
                backend="MediaElement"
                // progressColor={processColor}
                // url={track.path}
                // onReady={onReady}
                // onPlay={() => setIsPlaying(true)}
                // onPause={() => setIsPlaying(false)}
              />
            </div>
            <span
              onClick={toogleFav}
              role="button"
              className="hover-btn mx-2 position-relative">
              {isFav ? (
                <FaHeart
                  fill="#c91c1c"
                  size={"25px"}
                />
              ) : (
                <FaRegHeart
                  fill="#c91c1c"
                  size={"25px"}
                />
              )}
            </span>

            <Link
              onClick={() => window.open(track.path)}
              role="button"
              className="hover-btn mx-2 position-relative">
              <MdFileDownload
                fill="#c91c1c"
                size={"25px"}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }, [mediaElementState]);

  return (
    <Col
      className="item-track p-2"
      lg={12}
      md={12}
      // onClick={onClick}
    >
      <div className="d-flex align-items-center w-100">
        <div className="px-3 w-100 d-flex align-items-center">
          <img
            height={60}
            src={vinyle}></img>
          <p className="m-0 w-25">{track?.title}</p>
          <div className="d-flex">
            <span
              onClick={() => onPlayPause()}
              role="button"
              className="hover-btn mx-2">
              {isPlaying ? (
                <FaRegCirclePause
                  fill={"#c91c1c"}
                  size={"25px"}
                />
              ) : (
                <FaRegPlayCircle
                  fill={"#c91c1c"}
                  size={"25px"}
                />
              )}
            </span>
          </div>
          <div>{wavesurfer && JSON.stringify(Math.round(wavesurfer.getDuration()) / 100).replace(".", ":")}</div>
          <div className="waveform mx-2 w-75">
            <div ref={containerRef} />
          </div>
          <span
            onClick={toogleFav}
            role="button"
            className="hover-btn mx-2 position-relative">
            {isFav ? (
              <FaHeart
                fill="#c91c1c"
                size={"25px"}
              />
            ) : (
              <FaRegHeart
                fill="#c91c1c"
                size={"25px"}
              />
            )}
          </span>

          <Link
            onClick={() => window.open(track.path)}
            role="button"
            className="hover-btn mx-2 position-relative">
            <MdFileDownload
              fill="#c91c1c"
              size={"25px"}
            />
          </Link>
        </div>
      </div>
      {isPlay && createPortal(<ItemTest />, document.body)}
    </Col>
  );
};

export default ItemTrack;
