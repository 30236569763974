import { instance } from "./instanceApi";

let path_api = "/auth";

export const login = (data) => {
  return new Promise((resolve, reject) => {
    instance
      .post(`${path_api}/login`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("kokokko");

        reject("Identifiants incorrects");
      });
  });
};
