import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { Button, Table } from "reactstrap";
import * as CategoryService from "../../../services/category";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CategoryAdmin = () => {
  const [list, setlist] = useState([]);

  const navigate = useNavigate();

  const handleDelete = (id) => {
    CategoryService.remove(id).then(() => {
      setlist(list.filter((e) => e.id != id));
    });
  };

  useEffect(() => {
    CategoryService.getList().then((data) => {
      setlist(data);
    });
  }, []);

  return (
    <div className="admin-view d-flex h-100 w-100">
      <Sidebar></Sidebar>
      <div className="m-2 card mt-5 container main-admin px-5">
        <div className="d-flex my-3">
          <Link
            className="btn btn-primary"
            to={"/admin/category/form"}>
            Ajouter
          </Link>
        </div>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Titre</th>
              <th>Image</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            {list.length &&
              list.map((cat) => (
                <tr>
                  <th scope="row">{cat.id}</th>
                  <td>{cat.title}</td>
                  <td>
                    <img
                      width={100}
                      src={cat.image}></img>
                  </td>
                  <td>
                    <Button
                      className="mx-1"
                      onClick={() => navigate("/admin/category/form", { state: cat.id })}>
                      Edit
                    </Button>
                    <Button
                      className="mx-1"
                      onClick={() => handleDelete(cat.id)}>
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default CategoryAdmin;
