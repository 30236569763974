import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
});


function constructGetParams(params) {
    let urlParams;
    let i = 0;
    if (params) {
        for (const [key, value] of Object.entries(params)) {
            if (value != undefined && value != null) {
                if (i == 0) {
                    urlParams = `${key}=${value}`;
                } else {
                    urlParams += `&${key}=${value}`;
                }
                i++;
            }
        }
    }

    return urlParams;
}

export { instance, constructGetParams };