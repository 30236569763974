import { Routes, Route, Outlet, Link } from "react-router-dom";
import { adminRoutes, publicRoutes } from "./Routes";
import { AuthProtected } from "./AuthProtected";

export default function Navigation() {
  return (
    <>
      <Routes>
        {/* admin */}
        {adminRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={<AuthProtected>{route.component}</AuthProtected>}
          />
        ))}

        {publicRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={route.component}
          />
        ))}
      </Routes>
    </>
  );
}
