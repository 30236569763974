import React from "react";
import Dropzone from "react-dropzone";
import { FormFeedback } from "reactstrap";
import { uploadFile } from "../services/File";

function DropFile({ values, setValues, type, touched, errors, label }) {
    function handleAcceptedFiles(file) {
        uploadFile(file[0]).then((res) => {
            setValues(`${process.env.REACT_APP_API_URL}/file/${res}`);
        });
    }

    return (
        <div>
            <Dropzone
                multiple={false}
                accept={type == "img" ? { "image/jpeg": [], "image/png": [] } : { "audio/wav": [], "audio/mpeg": [] }}
                onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                    <div
                        style={{
                            position: "relative",
                            zIndex: 0
                        }}>
                        <div
                            className="dropzone dz-clickable"
                            style={{
                                height: 200,
                                position: "relative",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                zIndex: -2
                            }}>
                            {values && type == "img" ? (
                                <img
                                    src={`${values}`}
                                    className="img-fluid"
                                    alt="..."
                                    style={{
                                        height: "100%",
                                        zIndex: -1
                                    }}
                                />
                            ) : (
                                values && (
                                    <iframe
                                        src={`${values}`}
                                        className="img-fluid"
                                        alt="..."
                                        style={{
                                            height: "100%",
                                            zIndex: -1
                                        }}
                                    />
                                )
                            )}
                            {values && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        opacity: 1,
                                        zIndex: 0
                                    }}>
                                    <button
                                        className="btn btn-soft-danger"
                                        onClick={() => setValues(null)}>
                                        <i className="ri-delete-bin-2-line"></i>
                                        Supprimer {type == "img" ? "l'image" : "le pdf"}
                                    </button>
                                </div>
                            )}
                            <div
                                className="dz-message needsclick"
                                style={{
                                    position: "absolute",
                                    backgroundColor: "#00000045",
                                    height: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: 5,
                                    zIndex: -1
                                }}
                                {...getRootProps()}>
                                <div
                                    className="mb-3"
                                    style={{ color: "#FFFFFF" }}>
                                    <i className="display-4 ri-upload-cloud-2-fill" />
                                </div>
                                <h4 style={{ color: "#FFFFFF" }}>{label}</h4>
                                <h6 style={{ color: "#FFFFFF" }}>
                                    Glisser {type == "img" ? "une image" : "un pdf"} ici ou cliquer pour {type == "img" ? "la" : "le"} télécharger.
                                </h6>
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
            {touched && errors ? (
                <FormFeedback
                    type="invalid"
                    style={{ display: "block" }}>
                    {errors}
                </FormFeedback>
            ) : null}
        </div>
    );
}

export default DropFile;