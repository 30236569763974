import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Category } from "../components/Category";
import { CustomScroll } from "react-custom-scroll";
import { Footer } from "../components/Footer";
import * as CategoryService from "../services/category";
import * as MusicService from "../services/music";
import { Col, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import ItemTrack from "../components/ItemTrack";
import chevron from "./../assets/images/120890.png";
import { ScaleLoader } from "react-spinners";
import { useProfile } from "../hooks/UserHooks";
import VerticalLayout from "../components/VerticalLayout";
import bg from "../assets/images/home_background.svg";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa6";
import * as UserService from "../services/user";

const Home = () => {
  const { defaultVolume, userProfile, updateProfile } = useProfile();
  console.log(defaultVolume);

  const [userProfileDynamique, setUserProfileDynamique] = useState(userProfile);
  const [volumeTrack, setVolumeTrack] = useState(defaultVolume);
  const [selectedCat, setSelectedCat] = useState(null);
  const [listCategory, setListCategory] = useState(null);
  const [listTracks, setListTracks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTrack, setSelectedTrack] = useState(null);

  /**
   * OnClick sur la categorie
   * @param {*} id
   */
  const handleSelectedCat = (id) => {
    setSelectedCat(id);
  };

  /**
   * Ajout ou retirer des favories
   */
  const toggleFav = (track) => {
    let user = { ...userProfileDynamique };
    let isExist = user.favories.find((e) => e.id == track.id);
    if (isExist) {
      user.favories = user.favories.filter((e) => e.id != track.id);
    } else {
      user.favories.push(track);
    }
    setUserProfileDynamique(user);
    updateProfile(user);
  };

  /**
   * Récupération des categories depuis l'api
   */
  const handleCategorie = () => {
    CategoryService.getList().then((res) => {
      setListCategory(res);
    });
  };

  useEffect(() => {
    handleCategorie();
  }, []);

  useEffect(() => {
    // if (selectedCat) {
    setLoading(true);
    MusicService.getList({ categoryId: selectedCat })
      .then((res) => {
        // setSelectedTrack(res[0] || null);
        setListTracks(res);
        setLoading(false);
      })
      .catch(() => setLoading(true));
    // }
  }, [selectedCat]);
  console.log(userProfileDynamique?.favories);

  return (
    <>
      <VerticalLayout />

      <div className="body">
        <div className="container content-body">
          <Header />
          <div className="bg-main-container">
            <Row className="container-bg-gray">
              <Col
                lg={8}
                md={8}
                sm={0}
                className="title-site">
                <h1>Télécharge</h1>
                <h1 className="ilimited-text">sans limite</h1>
                <div className="content-title-btn mx-4 d-flex justify-content-between">
                  <Link
                    to={"/login"}
                    className="mt-2 text-decoration-none rounded-pill btn btn-color">
                    Connexion
                  </Link>
                  <Link
                    to={"/register"}
                    className="mt-2 text-decoration-none rounded-pill btn btn-outline-color">
                    S'inscrire
                  </Link>
                </div>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={8}></Col>
            </Row>
          </div>
          {/* <div className="d-flex flex-row justify-content-center">
          {listCategory &&
            listCategory.map((cat, i) => (
              <Category
                key={i}
                isActive={cat.id === selectedCat}
                onClick={() => handleSelectedCat(cat.id)}
                icon={cat.image}
              />
            ))}
        </div> */}
          <div className="d-flex flex-column align-items-start">
            <h4 className="d-flex align-items-center">
              <FaStar className="mx-2"></FaStar> Ajout récent :
            </h4>
            {loading ? (
              <div className="m-5">
                <ScaleLoader color="white" />
              </div>
            ) : (
              listTracks.map((track, i) => (
                <ItemTrack
                  isFav={userProfileDynamique && userProfileDynamique?.favories?.find((e) => e.id == track.id)}
                  isPlay={selectedTrack?.id == track.id}
                  key={i}
                  toogleFav={() => toggleFav(track)}
                  onClick={() => setSelectedTrack(track)}
                  track={track}
                  volume={volumeTrack ? volumeTrack : 0}
                />
              ))
            )}
          </div>
          <Footer selectedTrack={selectedTrack} />
        </div>
      </div>
    </>
  );
};

export default Home;
