import React, { useEffect, useState } from "react";

import * as AuthService from "../../services/auth";
import { useFormik } from "formik";
import { Button, Col, Container, Form, FormFeedback, Input, Label } from "reactstrap";
import { Footer } from "../../components/Footer";
import Header from "../../components/Header";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import VerticalLayout from "../../components/VerticalLayout";

const Login = () => {
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: yup.object({
      email: yup.string().email("Email invalide").required("Email obligatoire"),
      password: yup.string().required("mot de passe obligatoire")
    }),
    onSubmit: (values) => {
      AuthService.login(values)
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.user));
          localStorage.setItem("token", res.access_token);
          navigate("/");
        })
        .catch(() => {
          console.log("plplpl");

          toast.error("Identifiants incorrect");
        });
    }
  });

  return (
    <>
      <VerticalLayout />

      <div className="body mb-0">
        <Header />

        <ToastContainer />
        <div className="bg-main-container">
          <div className="main-container scrollable">
            <Container className="width-form">
              <div className="dark-container rounded p-4">
                <h2 className="text-white ">Bienvenue !</h2>
                <Form
                  onSubmit={validation.handleSubmit}
                  className="mt-5">
                  <Col className="m-3">
                    <Input
                      name="email"
                      invalid={validation.touched.email && validation.errors.email}
                      placeholder="Email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email}
                    />
                    {validation.errors.email && validation.touched.email && <FormFeedback>{validation.errors.email}</FormFeedback>}
                  </Col>
                  <Col className="m-3 mt-4">
                    <Input
                      name="password"
                      invalid={validation.touched.password && validation.errors.password}
                      placeholder="Mot de passe"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password}
                      type="password"
                    />
                    {validation.errors.password && validation.touched.password && <FormFeedback>{validation.errors.password}</FormFeedback>}
                  </Col>
                  <div className="d-flex m-2">
                    <Link
                      className="text-decoration-none"
                      to={"/register"}>
                      <p className="text-white">Pas inscrit ? rejoignez-nous !</p>
                    </Link>
                  </div>
                  <Button
                    color="primary"
                    className="">
                    Connexion
                  </Button>
                </Form>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
