import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Sidebar = () => {

    const navigate = useNavigate();

    return (
        <div className="sidebar">
            <Link className="active" to={"/"}>Site</Link>
            <Link to={'/admin/category'}>Categories</Link>
            <Link to={"/admin/music"}>Musique</Link>
            {/* <Link to={""}>About</Link> */}
        </div>
    )
}

export default Sidebar