import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { Button, Col, Form, FormFeedback, Input, Row } from "reactstrap";
import * as MusicService from "../../../services/music";
import * as CategorieService from "../../../services/category";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import DropFile from "../../../components/DropFile";

const MusicFormAdmin = () => {
  const { state } = useLocation();
  const [music, setMusic] = useState(null);
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (music && music.id) || 0,
      title: (music && music.title) || "",
      category: (music && music.category) || "",
      path: (music && music.path) || ""
    },
    validationSchema: yup.object({
      title: yup.string().required("Titre obligatoire"),
      category: yup.object().required("Categorie obligatoire"),
      path: yup.string().required("Music obligatoire")
    }),
    onSubmit: (values) => {
      MusicService.create(values).then(() => {
        navigate("/admin/music");
      });
    }
  });
  
  useEffect(() => {
    if (state) {
      MusicService.getOne(state).then((data) => {
        setMusic(data);
      });
    }
  }, []);

  useEffect(() => {
    CategorieService.getList().then((data) => {
      setCategory(data);
    });
  }, []);

  return (
    <div className="admin-view d-flex h-100">
      <Sidebar></Sidebar>
      <div className="card mt-5 container main-admin px-5">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
          className="m-5">
          <Row>
            <Col
              md={12}
              className="mb-2">
              <Input
                type="text"
                name="title"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.title}
                placeholder="Mettre un titre"
                invalid={validation.errors.title}
              />
              {validation.errors.title && validation.touched.title && (
                <FormFeedback
                  type="invalid"
                  style={{ display: "block" }}>
                  {validation.errors.title}
                </FormFeedback>
              )}
            </Col>
            <Col
              md={12}
              className="mb-2">
              <Input
                required={true}
                type="select"
                name="category"
                onChange={(e) => {
                  validation.setFieldValue("category", { id: e.target.value });
                }}
                invalid={validation.errors.category && validation.touched.category}
                onBlur={validation.handleBlur}>
                <option value={null}>Séléctionnez un catégorie</option>
                {category.length &&
                  category.map((cat) => (
                    <option
                      selected={validation.values.category?.id == cat.id ? true : false}
                      value={cat.id}>
                      {cat.title}
                    </option>
                  ))}
              </Input>
              {validation.errors.category && validation.touched.category && (
                <FormFeedback
                  type="invalid"
                  style={{ display: "block" }}>
                  {validation.errors.category}
                </FormFeedback>
              )}
            </Col>
            <Col md={12}>
              <DropFile
                values={validation.values.image}
                setValues={(value) => validation.setValues({ ...validation.values, path: value })}
                touched={validation.touched.image}
                errors={validation.errors.image}
                type={"mp3"}></DropFile>
              {validation.errors.path && validation.touched.path && (
                <FormFeedback
                  type="invalid"
                  style={{ display: "block" }}>
                  {validation.errors.path}
                </FormFeedback>
              )}
            </Col>
          </Row>
          <Button
            type="submit"
            className="my-2">
            Enregistrer
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default MusicFormAdmin;
