import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logged } from "../services/auth";
import * as UserService from "../services/user";

const useProfile = () => {
  const [loading, setLoading] = useState();

  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem("user")));
  const [defaultVolume, setVolumeTrack] = useState(0);

  const updateProfile = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    UserService.update(user);
  };

  return { userProfile, loading, defaultVolume, setVolumeTrack, updateProfile };
};

export { useProfile };
