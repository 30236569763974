import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WavesurferPlayer from "@wavesurfer/react";
import { useEffect, useState } from "react";
import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";
import { useProfile } from "../hooks/UserHooks";

const WaveformReact = ({ url, height = 30, color = "violet", processColor = "", buttonColor = null }) => {
  const { volumeTrack } = useProfile();
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause();
  };
 console.log(volumeTrack);
  useEffect(() => {
   
    if (wavesurfer) {
      wavesurfer.setVolume(parseFloat(volumeTrack));
    }
  }, [volumeTrack]);

  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <WavesurferPlayer
          height={height}
          waveColor={color}
          progressColor={processColor}
          url={url}
          onReady={onReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </div>
      <div
        onClick={() => onPlayPause()}
        role="button"
        style={{ color: buttonColor }}
        className="px-3">
        {isPlaying ? <FaCirclePause /> : <FaCirclePlay />}
      </div>
    </div>
  );
};

export default WaveformReact;
