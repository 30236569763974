import logo from './logo.svg';
import './App.scss';
import Navigation from './navigation/Navigation';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      
        <Navigation />
      </BrowserRouter>
    </div>
  );
}

export default App;
