import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import { Button, Table } from 'reactstrap';
import * as MusicService from '../../../services/music';
import { Link, useNavigate } from 'react-router-dom';
import WaveformReact from '../../../components/WaveformReact';

const MusicAdmin = () => {
    const [list, setlist] = useState([]);
    const [selectedtrack, setSelectedtrack] = useState([]);

    const navigate = useNavigate();

    const handleDelete = (id) => {
        MusicService.remove(id).then(() => {
            setlist(list.filter((e) => e.id !== id));
        });
    };

    const handleTracks = (id) => {
        let track = list.find((track) => track.id == id)
        setSelectedtrack(track)
    };

    useEffect(() => {
        MusicService.getList().then((data) => {
            setlist(data);
        });
    }, []);

    return (
        <div className='admin-view d-flex h-100 w-100'>
            <Sidebar />
            <div className='m-2 card mt-5 container main-admin px-5'>
                <div className='d-flex my-3'>
                    <Link className='btn btn-primary' to={"/admin/music/form"}>Ajouter</Link>
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Titre</th>
                            <th>Categorie</th>
                            <th>Musique</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0 && list.map((music) => {
                            return (
                                <tr key={music.id}>
                                    <th scope="row">{music.id}</th>
                                    <td>{music?.title}</td>
                                    <td>{music?.category?.title}</td>
                                    <td>
                                        {/* <button onClick={() => handleTracks(music.id)}>test</button> */}
                                        {/* {selectedtrack.id == music.id && <Waveform key={music.id} url={music.path} />} */}
                                        <WaveformReact url={music.path}></WaveformReact>
                                    </td>
                                    <td>
                                        <Button className='mx-1' onClick={() => navigate('/admin/music/form', { state: music.id })}>Edit</Button>
                                        <Button className='mx-1' onClick={() => handleDelete(music.id)}>Supprimer</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default MusicAdmin;
