import { instance } from "./instanceApi"

export function uploadFile(file) {
    return new Promise(async (resolve, reject) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            await instance
                .post("/file", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((res) => {
                    resolve(res.data.name);
                });
        } catch (err) {
            reject(err);
        }
    });
}