import React from "react";
import logo from "./../assets/images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaHouse } from "react-icons/fa6";
import { FaPhone, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useProfile } from "../hooks/UserHooks";
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";

const VerticalLayout = () => {
  const { userProfile } = useProfile();

  return (
    <>
      <div className="vertical-layout flex-column">
        <img
          className="App-logo"
          src={logo}
        />
        <div className="content-menu">
          <div className="h-100 my-4 flex-1">
            <Link
              to={"/"}
              className="nav-text-style text-decoration-none menu-item d-flex px-4 w-100 align-items-center my-1">
              <FaHouse
                className="mx-2"
                color="#c1c1c1"></FaHouse>
              <span className="mx-2 mt-1">Accueil</span>
            </Link>
            <Link
              to={"/about"}
              className="nav-text-style text-decoration-none menu-item d-flex px-4 w-100 align-items-center my-1">
              <FaUser
                className="mx-2"
                color="#c1c1c1"></FaUser>
              <span className=" mx-2 mt-1">Qui suis-je ?</span>
            </Link>
            <Link
              to={"/contact"}
              className="nav-text-style text-decoration-none menu-item d-flex px-4 w-100 align-items-center my-1">
              <FaPhone
                className="mx-2"
                color="#c1c1c1"></FaPhone>
              <span className="mx-2 mt-1">Contact</span>
            </Link>
          </div>
          <div className="align-self-bottom mb-2">
            {!userProfile ? (
              <Link
                to={"/login"}
                className="btn btn-color rounded-pill">
                Connexion
              </Link>
            ) : (
              <Link
                to={"/profile"}
                className="btn btn-color rounded-pill">
                {userProfile.firstname}
              </Link>
            )}
          </div>
        </div>
      </div>
      <div>
        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel">
          <div class="offcanvas-header">
            <h5
              class="offcanvas-title"
              id="offcanvasExampleLabel">
              <img
                className="App-logo"
                src={logo}
              />
            </h5>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div className="content-menu">
              <div className="h-100 my-4 flex-1">
                <Link
                  to={"/"}
                  className="nav-text-style text-decoration-none menu-item d-flex px-4 w-100 align-items-center my-1">
                  <FaHouse
                    className="mx-2"
                    color="#c1c1c1"></FaHouse>
                  <span className="mx-2 mt-1">Accueil</span>
                </Link>
                <Link
                  to={"/about"}
                  className="nav-text-style text-decoration-none menu-item d-flex px-4 w-100 align-items-center my-1">
                  <FaUser
                    className="mx-2"
                    color="#c1c1c1"></FaUser>
                  <span className=" mx-2 mt-1">Qui suis-je ?</span>
                </Link>
                <Link
                  to={"/contact"}
                  className="nav-text-style text-decoration-none menu-item d-flex px-4 w-100 align-items-center my-1">
                  <FaPhone
                    className="mx-2"
                    color="#c1c1c1"></FaPhone>
                  <span className="mx-2 mt-1">Contact</span>
                </Link>
              </div>
              <div className="align-self-bottom mb-2">
                {!userProfile ? (
                  <Link
                    to={"/login"}
                    className="btn btn-color rounded-pill">
                    Connexion
                  </Link>
                ) : (
                  <Link
                    to={"/profile"}
                    className="btn btn-color rounded-pill">
                    {userProfile.firstname}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerticalLayout;
