import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import { Button, Col, Form, FormFeedback, Input, Row } from 'reactstrap'
import * as CategoryService from '../../../services/category'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup';
import DropFile from '../../../components/DropFile'

const CategoryFormAdmin = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const [category, setCategory] = useState();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: (category && category.id) || 0,
            title: (category && category.title) || "",
            image: (category && category.image) || ""
        },
        validationSchema: yup.object({
            title: yup.string().required("Titre obligatoire"),
            image: yup.string().required('Fichier obligatoire')
        }),
        onSubmit: (values) => {
            CategoryService.create(values).then(() => {
                navigate('/admin/category');
            });

        }
    })

    useEffect(() => {
        if (state) {
            console.log(state);
            CategoryService.getOne(state).then((data) => {
                console.log(data);

                setCategory(data)
            })
        }
    }, [])


    return (
        <div className='admin-view d-flex h-100'>
            <Sidebar></Sidebar>
            <div className='card mt-5 container main-admin px-5'>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                }} className='m-5'>
                    <Row>
                        <Col md={12} className='mb-2'>
                            <Input
                                type="text"
                                name="title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title}
                                placeholder='Mettre un titre'
                            />
                            <FormFeedback>
                                {validation.errors.title}
                            </FormFeedback>
                        </Col>
                        <Col md={12}>
                            <DropFile values={validation.values.image} setValues={(value) => validation.setValues({ ...validation.values, image: value })} touched={validation.touched.image} errors={validation.errors.image} type={"img"}></DropFile>
                        </Col>
                    </Row>
                    <Button type='submit' className='my-2'>Enregistrer</Button>
                </Form>
            </div>
        </div>
    )
}

export default CategoryFormAdmin