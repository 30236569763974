import React, { useEffect, useState } from "react";
import logo from "./../assets/images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import { FaBars, FaGlassCheers, FaHamburger } from "react-icons/fa";
import { FaGlasses, FaGlide, FaMagnifyingGlass } from "react-icons/fa6";

const Header = () => {
  const [userLogged, setUserLogged] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    console.log(JSON.parse(user));
    setUserLogged(JSON.parse(user));
  }, []);

  return (
    <div className="Header">
      <Row>
        <Col
          xs={2}
          className="btn-offcanvas-menu">
          <a
            className="m-1 w-25"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample">
            <FaBars size={"30px"}></FaBars>
          </a>
        </Col>
        <Col xs={10}>
          <div className="container-search">
            <input
              placeholder="Recherche"
              className="search-input"></input>
            <FaMagnifyingGlass
              size={"20px"}
              className=""
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
